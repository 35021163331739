<template>
  <div class="calendar">
    <div class="opendiv" >
      <!-- 开放日程 -->
      <div class="changeCal">统计</div>
      <!--日期范围 -->
      <div>
        <span>开始日期</span>
        <span>
          <span style="width:100%;display:inline-block;">
            <el-date-picker
              v-model="statisticsTime"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="height: 2.0833vw;width: 100%;"
            ></el-date-picker>
          </span>
        </span>
      </div>
      <!-- 选择项目（课程关联） -->
      <div>
        <span>选择项目</span>
        <span>
          <el-select v-model="tongjiProjectId" placeholder="请选择" class="width100 themeInput">
            <el-option
              v-for="item in openProjectList"
              :key="'project_'+item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </span>
      </div>
      <br />
      <div class="center">
        <!-- <el-button class="close themecancelButton" @click="closeStatistic"></el-button> -->
        <el-button class="confirm themeButton" type="primary" @click="getStatistics">确 定</el-button>
      </div>
      <br>
      <div class="staticInfo" v-show="showtongjiInfo">
        <div>
          <span class="staticItem">在修群组</span>
          <span class="staticItemData">{{tongjiList.zxqz}}</span>
        </div>
        <div>
          <span class="staticItem">在修人数</span>
          <span class="staticItemData">{{tongjiList.zxrs}}</span>
        </div>
        <div>
          <span class="staticItem">开放单元数</span>
          <span class="staticItemData">{{tongjiList.kfdys}}</span>
        </div>
        <div>
          <span class="staticItem">单元人数</span>
          <span class="staticItemData">{{tongjiList.dyrs}}</span>
        </div>
        <div>
          <div class="staticText">
            <div class="staticTextBox">
              <span class="staticItem">项目容量</span>
              <span class="staticItemData">{{tongjiList.xmrl}}</span>
            </div>
            <div class="staticTextBox">
              <span class="staticItem themeColor">开课率</span>
              <span class="staticItemData themeColor">{{tongjiList.kkl}}%</span>
            </div>
          </div>
          <div class="staticProgress" >
            <el-progress
              color="#3E95B5"
              type="circle"
              width="50"
              :percentage="tongjiList.kkl"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
        <div>
          <div class="staticText">
            <div class="staticTextBox">
              <span class="staticItem">已预约人数</span>
              <span class="staticItemData">{{tongjiList.yyy}}</span>
            </div>
            <div class="staticTextBox">
              <span class="staticItem themeColor">预约完成度</span>
              <span class="staticItemData themeColor">{{tongjiList.yywcd}}%</span>
            </div>
          </div>
          <div class="staticProgress">
            <el-progress
              color="#3E95B5"
              type="circle"
              width="50"
              :percentage="tongjiList.yywcd"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
        <div>
          <div class="staticText">
            <div class="staticTextBox">
              <span class="staticItem">已完成人数</span>
              <span class="staticItemData">{{tongjiList.ywcrs}}</span>
            </div>
            <div class="staticTextBox">
              <span class="staticItem themeColor">实验完成度</span>
              <span class="staticItemData themeColor">{{tongjiList.sywcd}}%</span>
            </div>
          </div>
          <div class="staticProgress">
            <el-progress
              color="#3E95B5"
              type="circle"
              width="50"
              :percentage="tongjiList.sywcd"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
    <div style="height:88vh;width:1%;float:left"></div>
    <div class="tab">
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程工作量统计" name="first">
              <!-- 筛选div -->
              <div class="selectVir">
                  <div style="float:left">
                      <el-select v-model="course_id" placeholder="请选择课程！" clearable class="setInput width350">
                          <el-option v-for="(item, index) in courseList" :key="index" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                      <el-select v-model="school_id" placeholder="请选择学校！" clearable class="setInput width350">
                          <el-option v-for="(item, index) in schoolList" :key="index" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                      <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                      <!-- 不点统计无法导出 -->
                      <el-button class="chartsButton" @click="getAllProjectByCourseidWorkStat">统计</el-button>
                  </div>
                  <div style="float:right">
                      人时数总计：{{courseTotal}}
                  </div>
              </div>
              <!-- 表格div -->
              <div v-for="item in projectDataList" :key="item.id" >
                  <!-- 标题 -->
                  <div class="title">
                      {{item.name}}
                  </div>
                  <!-- 表格 -->
                  <div >
                      <el-table
                      :data="item.info"
                      style="width: 100%">
                          <el-table-column
                              prop="name"
                              label="人员">
                          </el-table-column>
                          <el-table-column
                              prop="ping_ri"
                              label="单元数">
                          </el-table-column>
                          <el-table-column
                              prop="zrsjs"
                              label="人数">
                          </el-table-column>
                          <el-table-column
                              prop="zrsjs"
                              label="人学时数">
                              <template slot-scope="scope">
                                  {{scope.row.hours * scope.row.zrsjs}}
                              </template>
                          </el-table-column>
                          <el-table-column
                              prop="danyuanbaifenbi"
                              label="单元百分比">
                          </el-table-column>
                      </el-table>
                  </div>
              </div>
          </el-tab-pane>
          <el-tab-pane label="教师工作量统计" name="second">
              <!-- 筛选div -->
              <div class="selectVir">
                  <div style="float:left">
                    <el-input v-model="teacherName" placeholder="请输入教师名称！" style="width: 155px;margin-right: 10px;"></el-input>
                    <el-select v-model="teacher_school_id" placeholder="请选择学校！" clearable class="setInput width350">
                        <el-option v-for="(item, index) in schoolList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <el-date-picker v-model="openDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    <!-- 不点统计无法导出 -->
                    <el-button class="chartsButton" @click="getOpenDateWorkStat">统计</el-button>
                  </div>
                  <div style="float:right">
                      人时数总计：{{openTotal}}
                  </div>
              </div>
              <!-- 表格div -->
              <div >
                  <el-table
                  :data="openDataList"
                  style="width: 100%">
                      <el-table-column
                          prop="name"
                          label="项目名称"
                          width="400">
                      </el-table-column>
                      <el-table-column
                          prop="ping_ri"
                          label="单元数"
                          width="200">
                      </el-table-column>
                      <el-table-column
                          prop="zrsjs"
                          label="人数">
                      </el-table-column>
                      <el-table-column
                          prop="hours"
                          label="学时数">
                      </el-table-column>
                      <el-table-column
                          prop="hoursPerson"
                          label="人时数">
                      </el-table-column>
                  </el-table>
              </div>
          </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/DY/tongji.scss";
  .el-main {
    padding: 10px !important;
  }
</style>

<script>
  import Calendar from "@/assets/js/DY/tongji.js";
  export default {
    ...Calendar
  };
</script>
