import{Message} from  'element-ui'
import { encryptCode,decryptCode } from "@/utils/function";
import { request_sign } from '@/utils/const';
import API from '@/api/teacher/calendar/calendar'
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "StudentsList",
  components: {},
  inject:['reload'],
  data() {
    return {
      showStatistics:true,
      isStatistics:true,
      isStatistics:0,
      openProjectList:[],
      statisticsTime:[],
      tongjiProjectId:"",
      tongjiList:[],
      showtongjiInfo:false,
      activeName:'first',
      schoolList:[
        {"id":10161,"name":"大连医科大学"},
        {"id":99990,"name":"大连医科大学附属第一医院"},
      ],
      courseTotal:0,
      projectDataList:[],
      virtualDate:[],
      course_id:"",
      school_id:"",
      courseList:[],
      openDate:[],
      openDataList:[],
      courseTotal:0,
      openTotal:0,
      teacher_school_id:"",
      teacherName:"",

    };
  },
  created() {
    let self = this;
    self.getData();
  },
  methods: 
  {
    getOpenDateWorkStat()
    {
        // try 
        // {
            let self = this;
            let data = {
                start:self.openDate[0],
                end:self.openDate[1],
                teacherName:self.teacherName,
                school_id:self.teacher_school_id
            };
            nprogressStart();
            self.$Loading.show();
            self.axios.post('/index.php/backStatistics/getOpenDateWorkStat', data).then(result => {
              console.log(result)
              // 关闭loading
              nprogressClose();
              self.$Loading.hide();
              if (result.data.code > 0) 
              {
                self.openDataList = result.data.data;
                self.openTotal = result.data.total;
              }
              else
              {
                self.openDataList = [];
                self.openTotal = 0;
                Message.error(result.data.msg);
              }
            });            
        // } 
        // catch (error) 
        // {
        //     Message.error('系统错误');
        // }
    },
    getAllProjectByCourseidWorkStat()
    {
        try 
        {
            let self = this;
            let data = {
                start:self.virtualDate[0],
                end:self.virtualDate[1],
                courseid:self.course_id,
                school_id:self.school_id,
            };
            nprogressStart();
            self.$Loading.show();
            self.axios.post('/index.php/backStatistics/getAllProjectByCourseidWorkStat', data).then(res => {
              console.log(res)
              // 关闭loading
              nprogressClose();
              self.$Loading.hide();
              if (res.data.code > 0) 
              {
                self.projectDataList = res.data.data;
                console.log(self.projectDataList)
                self.projectDataList.forEach( (element,key) => {
                    element.info.forEach((item,k) => {
                        self.projectDataList[key].info[k].hoursPerson = parseFloat(item.hours) * parseFloat(item.zrsjs);
                        self.courseTotal += parseFloat(self.projectDataList[key].info[k].hoursPerson);
                    })                        
                });
              }
              else
              {
                Message.error(res.data.msg);
              }
            });           
        } 
        catch (error) 
        {
            Message.error('系统错误');
        }
    },
    handleClick(tab, event) 
    {
        let self = this;
        self.activeName = tab.name;
        if(self.activeName == 'first')
        {
            // self.getAllProjectByCourseidWorkStat();
        }
        else
        {
            // self.getOpenDateWorkStat();
        }
    },
    getData()
    {
      let self = this;
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      self.axios.post('/index.php/backStatistics/getTongjiData', {}).then(res => {
        console.log(res)
        if (res.data.code > 0) 
        {
          self.openProjectList = res.data.projectList;
          self.courseList = res.data.courseList;
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },
    getStatistics() 
    {
      try {
          let self = this;
          console.log(22222)
          let data = {
              start: self.statisticsTime[0],
              end: self.statisticsTime[1],
              project_id: self.tongjiProjectId
          }
          API.getTongjiData(data)
              .then((res) => {
                  if (res.code > 0) 
                  {
                      self.tongjiList = res.data
                      self.showtongjiInfo = true
                      // Message.success(res.msg)

                      // this.$Tips({
                      //     // 消息提示内容
                      //     message:res.msg,
                      //     // 消息提示类型（success-成功,warning-警告/失败）
                      //     messageType:'success',
                      //     displayTime:1500
                      // })
                  }
                  else {
                      this.$Tips({
                          // 消息提示内容
                          message: res.msg,
                          // 消息提示类型（success-成功,warning-警告/失败）
                          messageType: 'warning',
                          displayTime: 1500
                      })
                  }
              })
      }
      catch (error) {
          // Message.error('网络错误')
      }
    },
  },
};















